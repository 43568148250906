<template>
    <section class="px-5">
        <b-form-group>
            <b-input-group
                class="d-flex align-items-end justify-content-center"
                size="lg"
            >
                <v-select
                    v-model="filters.form_id"
                    :clearable="true"
                    @search="onSearchForms"
                    @open="onSearchForms"
                    :get-option-label="(option) => option.name"
                    :options="forms"
                    :reduce="(option) => option._id"
                    :loading="loading.campaign"
                    :selectable="(option) => !forms.includes(option._id)"
                    class="flex-grow-1 mx-2 mt-2"
                    placeholder="Buscar Formulário"
                >
                    <span slot="no-options">Desculpe, não há opções correspondentes.</span>
                </v-select>
                <select
                    v-model="filters.phone_id"
                    class="form-control like-dropdown mr-2 flex-grow-1 mx-2 mt-2"
                    style="min-width: 180px"
                    @click="loadFilters"
                >
                    <option :value="null" selected>Número de Disparo</option>
                    <option :value="-1" disabled v-if="loading.filters">Carregando...</option>
                    <option
                        v-for="(phone, i) in filters_options.phones"
                        :key="`phone-option-${i}`"
                        :value="phone._id"
                    >
                        {{ formatPhone(phone.phone) }}
                    </option>
                </select>

                <select
                    v-if="user.can_shot"
                    v-model="filters.campaign_id"
                    class="form-control like-dropdown"
                    style="min-width: 120px"
                    @click="loadFilters"
                >
                    <option :value="null" selected>Campanha</option>
                    <option :value="-1" disabled v-if="loading.filters">Carregando...</option>
                    <option
                        v-for="(campaign, i) in filters_options.campaigns"
                        :key="`campaign-option-${i}`"
                        :value="campaign._id"
                    >
                        {{ campaign.name }}
                    </option>
                </select>

                <div class="d-flex flex-column text-left flex-grow-1 mx-2 mt-2">
                    <label class="m-0" for="filter-score">Score</label>
                    <b-form-rating
                        id="filter-score"
                        v-model="filters.score"
                        class="rating-input"
                    ></b-form-rating>
                </div>

                <div class="d-flex flex-column text-left flex-grow-1 mx-2 mt-2">
                    <label class="m-0" for="filter-start">Início</label>
                    <b-form-datepicker
                        id="filter-start"
                        v-model="filters.initial_date"
                        :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                        }"
                        :max="max_date"
                        locale="pt-BR"
                        placeholder="Data inicial"
                    ></b-form-datepicker>
                </div>

                <div class="d-flex flex-column text-left flex-grow-1 mx-2 mt-2">
                    <label class="m-0" for="filter-final">Final</label>
                    <b-form-datepicker
                        id="filter-final"
                        v-model="filters.final_date"
                        :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                        }"
                        :min="min_date"
                        :max="today"
                        locale="pt-BR"
                        placeholder="Data final"
                    ></b-form-datepicker>
                </div>

                <b-button
                    class="font-weight-bold px-5 flex-grow-1 mx-2 mt-2"
                    style="height: 35px;"
                    variant="primary"
                    @click.prevent="fetchReports"
                >
                    Filtrar
                </b-button>
            </b-input-group>
        </b-form-group>

        <div class="row mt-5 position-relative">
            <loading v-if="loading.geral"/>
            <div
                class="col-12 col-lg-5 d-flex justify-content-center align-items-start py-3"
            >
                <div class="d-flex justify-content-center flex-wrap align-items-center">
                    <div class="icon-with-background mr-3 p-5">
                        <img
                            src="@/assets/images/icons/user-group-man.svg?data"
                            alt=""
                        />
                    </div>
                    <div
                        class="d-flex flex-column text-left justify-content-center"
                    >
                        <span>Clientes cadastrados</span>
                        <span class="big-number text-blue">
                            {{ customersGeral.customers_count }}
                        </span>
                        <a
                            class="text-dark text-decoration-underline"
                            href="#download"
                            @click.prevent="downloadCustomersSheet()"
                        >
                            exportar
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-7 d-flex flex-column">
                <div class="d-flex flex-column overflow-hidden py-3">
                    <h6 class="text-blue text-left font-weight-bold">
                        Números Cadastrados
                    </h6>
                    <div>
                        <horizontal-chart-bar :data="customersGeral.registered_numbers"/>
                    </div>
                </div>
                <div class="row d-flex flex-wrap">
                    <div class="col-12">
                        <div class="d-flex flex-column overflow-hidden py-3">
                            <h6 class="text-blue text-left font-weight-bold">
                                Bloqueios
                            </h6>
                            <div>
                                <horizontal-chart-bar
                                    :data="customersGeral.blockages"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <h3
                class="text-left text-blue font-weight-bolder mr-2 mb-4 mt-5"
            >
                Clientes cadastrados e ativos por Número
            </h3>
            <div class="px-3 rounded border border-secondary">
                <clients-number-table/>
            </div>
        </div>

        <div>
            <div class="d-flex justify-content-between mb-4 mt-5">
                <div class="d-flex">
                    <h3 class="text-left text-blue font-weight-bolder mr-2">
                        Resultado clientes por segmento
                    </h3>
                </div>
                <div class="d-flex justify-content-between">
                        <select @change="filterSegment" v-model="segmentType" class="custom-select mx-1">
                            <option value="score">Score</option>
                            <option value="mark">Marcador</option>
                            <option value="chatStatus">Status da conversa</option>
                            <option v-if="user.can_shot" value="campaign">Campanha</option>
                            <option value="phone">Número de disparo</option>
                        </select>
                        <b-form-select
                            @change="filterSegment"
                            v-model="resultsSort"
                            :options="resultsSortOptions"
                        ></b-form-select>
                </div>

            </div>
            <div
                class="d-flex flex-column align-items-start p-3 rounded border border-secondary"
            >
                <loading v-if="loadingResults"></loading>
                <table class="table table-borderless">
                    <tbody>
                        <tr
                            v-for="(result, index) in segmentList"
                            class="custom-table-row fit-content"
                            :key="`rating--table--row--${index}`">
                            <td>
                                <b-form-rating
                                    v-if="segmentType === 'score'"
                                    :value="resultsSort !== null ? 5 - result[0] : 5 - index"
                                    class="rating-input mr-4 text-blue"
                                    readonly
                                />
                                <div v-else class="text-blue font-weight-bold">
                                    <span v-if="segmentType === 'chatStatus'"  :class="'text-' + getChatType(index).color">
                                    <font-awesome-icon :class="'text-' + getChatType(index).color"
                                                       :icon="'fa-solid ' + getChatType(index).icon"/>
                                        {{ getChatType(index).label }}
                                    </span>
                                    <span v-else>{{ index }}</span>
                                </div>
                            </td>
                            <td class="vertical-middle">
                                <span >{{ resultsSort !== null ? result[1] : result }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import {
    BFormSelect,
    BFormGroup,
    BInputGroup,
    BFormRating,
    BFormDatepicker,
    BButton,
} from "bootstrap-vue";
import VSelect from "vue-select";
import HorizontalChartBar from "./components/HorizontalChartBar.vue";
import Loading from "@/views/components/Loading.vue";
import api from '@/api';
import {dateToMonthString} from '@/libs/utils';
import clientsNumberTable from "./components/ClientsNumberTable";
import { chatTypes } from "@/views/pages/dashboard/messages/constants";
import { formatPhone } from "@/api/functions";

export default {
    components: {
        BFormGroup,
        BInputGroup,
        BFormSelect,
        BFormRating,
        BFormDatepicker,
        BButton,
        HorizontalChartBar,
        Loading,
        clientsNumberTable,
        VSelect,
    },
    data() {
        return {
            loadingResults: false,
            chatTypes,
            appliedFilters: null,
            filters: {
                search: null,
                phone_id: null,
                campaign_id: null,
                score: null,
                social_network: null,
                initial_date: null,
                final_date: null,
                form_id: null,
            },
            filters_options: {
                campaigns: [],
                phones: [],
            },
            customersGeral: this.prepareCustomerReportDataToShow(null),
            resultsSortOptions: [
                {value: null, text: "Ordenar por"},
                {value: "asc", text: "Ascendente"},
                {value: "desc", text: "Descendente"},
            ],
            resultsSort: null,
            segmentType: null,
            resultsValues: [],
            forms: [],
            loading: {
                geral: false,
                filters: false
            }
        };
    },
    created() {
        this.fetchReports();
        this.filterSegment();
    },
    computed: {
        segmentList() {
            if (this.resultsSort === null) return this.resultsValues;
            const sortDesc = this.resultsSort === 'desc';
            const reduceOption = this.segmentType === 'score'
                ? (a, v) => ({...a, [v]: v})
                : (a, v) => ({...a, [v[0]]: v});

            return Object.entries(this.resultsValues)
                .sort(([, a], [, b]) => sortDesc ? b - a : a - b)
                .reduce(reduceOption, {});
        },
        today() {
            return new Date();
        },
        min_date() {
            if (this.filters.initial_date !== null) {
                return this.filters.initial_date;
            }

            return new Date(0);
        },
        max_date() {
            if (this.filters.final_date !== null) {
                return this.filters.final_date;
            }
            return this.today;
        }

    },
    methods: {
        formatPhone,
        loadFilters() {
            if(!(this.filters_options.phones.length === 0 || this.filters_options.campaigns.length === 0)) return;

            this.loading.filters = true;
            let promises = [
                api.get('/phones', {all: 1})
                .then(res => {
                    const { type, body } = res.data;

                    if(type === 'success') {
                        return this.filters_options.phones = body;
                    }
                }),
                api.get('/campaigns', {all: 1})
                .then(res => {
                    const { type, body } = res.data;

                    if(type === 'success') {
                        return this.filters_options.campaigns = body;
                    }
                })
            ];

            Promise.all(promises).finally(() => this.loading.filters = false);
        },
        onSearchForms() {
            this.loadingResults = true;
            api.get('/forms', {
                search: this.filters.search,
            }).then(res => {
                const { type, body } = res.data;

                if(type === 'success') {
                    this.forms = body;
                }
            }).finally(() => this.loadingResults = false);
        },
        /**
         * Ao clicar em "Filtrar" essa função é chamada e deve contemplar todas as funções
         * que dependem dos filtros.
         */
        fetchReports() {
            this.fetchCustomersGeralReports();
        },
        fetchCustomersGeralReports() {
            this.loading.geral = true;

            api
                .get('/reports/customers/geral', {
                    params: {
                        ...this.filters
                    }
                })
                .then(response => {
                    this.customersGeral = this.prepareCustomerReportDataToShow(response.data.body);
                    this.appliedFilters = Object.assign({}, this.filters);
                })
                .finally(() => {
                    this.loading.geral = false;
                })
        },
        prepareCustomerReportDataToShow(data) {
            const periods = [
                dateToMonthString(data !== null ? data.period.from : new Date()),
                dateToMonthString(data !== null ? data.period.to : new Date())
            ];

            return {
                customers_count: data != null ? data.customers_count : 0,
                spam_count: data != null ? data.spam_count : 0,
                registered_numbers: {
                    labels: ["Sem Whatsapp", "Bloqueios"],
                    values:
                        data !== null
                            ? [data.registered_numbers.without_whatsapp, data.registered_numbers.blockages]
                            : [0, 0],
                    colors: ["#378792", "#FF3B30"],
                    periods
                },
                blockages: {
                    labels: ["Recebidos", "Aplicados"],
                    values:
                        data !== null
                            ? [data.blockages.received, data.blockages.applied]
                            : [0, 0],
                    colors: ["#FF3B30", "#FF9500"],
                    periods,
                },
                period: {
                    from: "2022-04-27T11:39:46.826000Z",
                    to: "2022-04-27T11:48:10.744000Z"
                }
            }
        },
        downloadCustomersSheet() {
            this.$swal({
                title: 'Preparando arquivo...',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    this.$swal.showLoading()
                },
            })

            api.get(`/customers/export/`, {responseType: 'blob', params: this.appliedFilters})
                .then(response => {
                    if (response.status === 200) {
                        const blob = new Blob([response.data], {type: 'application/xlsx'})
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = 'clientes-cadastrados.xlsx'
                        link.click()
                        URL.revokeObjectURL(link.href)
                        return this.$swal.close();
                    }
                    throw new Error('');
                }).catch(() => {
                this.$swal({
                    title: "Arquivo indísponível",
                    text: "Não foi possível realizar o download do arquivo.",
                    icon: "error",
                    confirmButtonText: 'Continuar',
                    confirmButtonColor: "#317f98",
                })
            })
        },

        filterSegment() {
            const params = {
                order: this.resultsSort,
                segment: this.segmentType
            };
            this.resultsValues = null;
            this.loadingResults = true;
            api.get('/reports/customersBySegment', {params})
            .then(response => {
                const {result, segment} = response.data.body;
                this.segmentType = segment;
                this.resultsValues = result;
            })
            .finally(() => {
                this.loadingResults = false;
            })
        },

        getChatType(status) {
            return chatTypes.find((value) => value.id === status);
        },
    },
};
</script>
<style scoped>
.custom-table-row {
    height: 25px;
}

.fit-content {
    width: fit-content;
    max-width: 400px;
}

.vertical-middle {
    vertical-align: middle;
}

table {
    width: fit-content;
}

table td, table tr {
    height: 25px;
    text-align: left;
}

table td {
    padding-top: .2rem;
    padding-bottom: 0;
}
</style>
