<script>
import { truncate } from '@/api/functions';
import {HorizontalBar} from 'vue-chartjs';

export default {
    extends: HorizontalBar,
    props: {
        data: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            callback: function(value) {
                                return truncate(value, 20);   
                            },
                        },
                        max: 10
                    }]
                },
                ...this.options
            },
            chartData: this.$props.data
        }
    },
    mounted() {
        this.renderChart(this.data, this.chartOptions);
    },    
    watch: {
        data: {
            handler(val) {
                if(val != null && val != this.chartData) {
                    this.chartData = this.$props.data
                    this.renderChart(this.chartData, this.chartOptions);
                }
            },
            deep: true
        }
    }
}
</script>
