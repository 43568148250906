<template>
    <div class="common-table">
        <!-- Header -->
        <div
            v-if="!desactiveHeader"
            class="w-100 d-flex justify-content-between my-4 header-custom-table"
        >
            <h3 v-if="title" class="text-blue font-weight-bolder">
                {{ title }}
            </h3>
            <b-form-group v-show="isHeader">
                <b-input-group class="d-flex" size="lg">
                    <v-select
                        v-model="sortBy"
                        :options="sortOptions"
                        class="mr-2 w-full custom"
                        placeholder="Filtrar por"
                    />
                    <v-select
                        v-model="sortDesc"
                        :options="optionsOrder"
                        class="custom"
                        placeholder="Classificar por"
                    />
                    <!-- basic search -->
                    <b-input-group class="input-group-merge search">
                        <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" size="25"/>
                        </b-input-group-prepend>
                        <b-form-input
                            v-model="filter"
                            placeholder="Buscar Formulário"
                        />
                    </b-input-group>
                </b-input-group>
            </b-form-group>
        </div>
        <!-- Main table element -->
        <b-table
            :borderless="true"
            :fields="fields"
            :filter="filter"
            :items="items"
            :sort-by.sync="sortBy.code"
            :sort-desc.sync="sortDesc.code"
            :sticky-header="true"
            responsive="sm"
            thead-tr-class="text-blue"
        >
            <template #cell(nome)="row">
                <span class="name text-black">{{ row.value }}</span>
            </template>

            <template #cell(coletados)="row">
                {{ row.value }}
            </template>

            <template #cell(data)="row">
                {{ row.value }}
            </template>

            <template #cell(actions)="row">
                <b-link
                    v-for="action in columnActions"
                    :key="action.text"
                    :to="{ name: action.link }"
                    class="mr-1"
                >{{ action.text }}
                </b-link
                >
                <b-link @click="row.toggleDetails">
                    <font-awesome-icon
                        :icon="
                            row.detailsShowing
                                ? 'fa-solid fa-caret-up'
                                : 'fa-solid fa-caret-down'
                        "
                    />
                </b-link>
            </template>

            <template #row-details>
                <b-card></b-card>
            </template>

            <template #custom-foot :columns="items.length">
                <slot name="footer"/>
            </template>
        </b-table>
    </div>
</template>

<script>
import {
    BTable,
    BCard,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
    props: ["title", "fields", "items", "columnActions", "isHeader", "desactiveHeader"],
    data() {
        return {
            optionsOrder: [
                {label: "Asc", code: true},
                {label: "Desc", code: false},
            ],
            sortBy: "",
            sortDesc: "",
            sortDirection: "asc",
            filter: "",
        };
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {label: f.label, code: f.key};
                });
        },
    },
    components: {
        BTable,
        BLink,
        BCard,
        BFormGroup,
        BInputGroup,
        vSelect,
        BFormInput,
        BInputGroupPrepend,
    },
};
</script>

<style></style>
