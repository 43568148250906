<template>
    <article class="row w-100 position-relative" :class="{ 'mt-5': readOnly }">
        <div v-if="!readOnly" class="col-12 col-xl-6">
            <div class="row align-items-center mt-4 mb-5">
                <div class="col-12 my-1 d-flex align-items-center">
                    <multiselect
                        v-model="chart.primary"
                        :options="primaryParams"
                        label="name"
                        :showLabels="false"
                        placeholder="Selecione o parâmetro principal"
                    >
                        <template v-slot:noResult>
                            Nenhum elemento encontrado.
                        </template>
                        <template v-slot:noOptions>
                            Nenhum elemento encontrado.
                        </template>
                    </multiselect>

                    <span
                        role="button"
                        @click="() => this.$emit('remove')"
                        v-b-tooltip="'Remover gráfico'"
                        class="d-flex align-items-center"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-trash-can"
                            class="text-danger p-2 ml-2"
                        />
                    </span>
                </div>
            </div>

            <div
                v-for="(field, i) in chart.fields"
                :key="`chart-${id}-field-${i}`"
                class="d-flex flex-column align-items-start mb-4"
            >
                <span class="text-secondary font-weight-bold mb-2">Campo {{ i + 1 }}</span>
                <div class="d-flex align-items-center w-100">
                    <div class="d-flex align-items-center mr-3" @click="openChooseColorModal(i, field.color)">
                        <div
                            :style="`background-color: ${field.color}`"
                            class="color-preview mr-2"
                        ></div>
                        <font-awesome-icon @click="openChooseColorModal(i, field.color)" icon="fa-solid fa-eye-dropper"/>
                    </div>

                    <div class="row w-100 align-items-center">
                        <div :class="{'col-7': tertiaryParams.length > 0, 'col-12': tertiaryParams.length === 0}">
                            <multiselect
                                v-model="chart.secondary"
                                :options="secondaryParams"
                                label="name"
                                :showLabels="false"
                                placeholder="Parâmetro"
                                class="w-100"
                            >
                                <template v-slot:noResult>
                                    Vazio
                                </template>
                                <template v-slot:noOptions>
                                    Vazio
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-5">
                            <multiselect
                                v-if="tertiaryParams.length > 0"
                                v-model="field.tertiary"
                                :options="filterOptions(tertiaryParams)"
                                label="name"
                                :showLabels="false"
                                placeholder="Tipo"
                                class="w-100"
                            >
                                <template v-slot:noResult>
                                    Vazio
                                </template>
                                <template v-slot:noOptions>
                                    Vazio
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <span
                        role="button"
                        @click="() => chart.fields.splice(i, 1)"
                        v-b-tooltip="'Remover campo'"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-close"
                            class="text-danger p-2 ml-2"
                        />
                    </span>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-start">
                <h5
                    role="button"
                    class="text-left text-secondary font-weight-bolder"
                    @click="addField"
                    v-if="chart.primary && chart.fields.length < tertiaryParams.length"
                >
                    <font-awesome-icon icon="fa-solid fa-plus" class="mr-4" /> Adicionar novo campo
                </h5>
                <span v-if="!chart.primary">
                    Selecione um parâmetro principal para continuar
                </span>
            </div>
        </div>
        <div class="col-12 position-relative" :class="{'col-xl-6': !readOnly}">
            <loading v-if="chartLoading" />
            <div v-if="!readOnly" class="row align-items-center mt-4 mb-5">
                <div class="col-12 col-lg-6 col-xl-4 my-1">
                    <date-picker
                        placeholder="Data inicial"
                        prefix-class="xmx"
                        size="lg"
                        class="w-100"
                        type="date"
                        format="DD/MM/YYYY"
                        value-type="YYYY-MM-DD"
                        :lang="datePickerPt"
                        v-model="chart.from_date"
                        :disabled-date="(date) => disableDateTime(date, chart.to_date, 'max', false, false)"
                    ></date-picker>
                </div>
                <div class="col-12 col-lg-6 col-xl-4 my-1">
                    <date-picker
                        placeholder="Data final"
                        prefix-class="xmx"
                        size="lg"
                        class="w-100"
                        type="date"
                        format="DD/MM/YYYY"
                        value-type="YYYY-MM-DD"
                        :lang="datePickerPt"
                        v-model="chart.to_date"
                        :disabled-date="(date) => disableDateTime(date, chart.from_date, 'min', false, false)"
                    ></date-picker>
                </div>
                <div class="col-12 col-lg-12 col-xl-4 my-1">
                    <multiselect
                        v-model="chart.type"
                        :options="chartTypes"
                        label="name"
                        :showLabels="false"
                        placeholder="Gráfico"
                    >
                        <template v-slot:noResult>
                            Vazio
                        </template>
                        <template v-slot:noOptions>
                            Vazio
                        </template>
                    </multiselect>
                </div>
            </div>

            <div :class="{'row': !readOnly}">
                <div :class="{'col-12 col-lg-8 offset-lg-2': !readOnly}">
                    <div v-if="chart.data != null && chart.type != null">
                        <chart-bar :data="chart.data" v-if="chart.type.key === 'bar'"/>
                        <chart-line :data="chart.data" v-else-if="chart.type.key === 'line'"/>
                        <chart-column :data="chart.data" v-else-if="chart.type.key === 'column'"/>
                        <chart-pie :data="chart.data" v-else-if="chart.type.key === 'sector'"/>
                    </div>
                    <div v-if="!readOnly">
                        <span v-if="chart.data == null && chart.type == null">Adicione parâmetros e escolha o tipo do gráfico</span>
                        <span v-else-if="chart.data == null">Adicione parâmetros para gerar o gráfico</span>
                        <span v-else-if="chart.type == null">Escolha o tipo do gráfico</span>
                    </div>
                    <div v-else>
                        <span v-if="chart.data == null || chart.type == null">Não foi possível exibir o gráfico por ausência de dados</span>
                        <span class="mx-2" v-if="chart.from_date">Início: {{ moment(chart.from_date).format('DD/MM/YYYY') }}</span>
                        <span class="mx-2" v-if="chart.to_date">Fim: {{ moment(chart.to_date).format('DD/MM/YYYY') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <choose-color-modal
            :id="`${id}-choose-modal`"
            @choose="changeColor($event)"
            :options="modalChooseColor"
            v-if="!readOnly"
        />
        <loading v-if="loading" />
    </article>
</template>

<script>
import api from '@/api';
import ChooseColorModal from "@/views/pages/dashboard/reports/modals/ChooseColorModal";
import ChartBar from './ChartBar.vue';
import ChartColumn from './ChartColumn.vue';
import ChartPie from './ChartPie.vue';
import ChartLine from './ChartLine.vue';
import Multiselect from 'vue-multiselect'
import { uuid } from 'vue-uuid';
import DatePicker from 'vue2-datepicker';
import Loading from '@/views/components/Loading.vue';
import { datePickerPt } from "@/views/pages/dashboard/campaigns/constants";
import { disableDateTime } from "@/api/functions";
import moment from 'moment';

const colors = ['#317f98', '#5ecbdd', '#df6868', '#c6d3f1', '#f26f51', '#5ecbdd', '#f5b09d', '#3ab5a0'];

export default {
    components: {
        Loading,
        ChartBar,
        DatePicker,
        ChartColumn,
        ChartPie,
        ChartLine,
        Multiselect,
        ChooseColorModal
    },
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    id: uuid.v4(),
                    primary: null,
                    secondary: null,
                    from_date: null,
                    to_date: null,
                    fields: [],
                    data: null,
                    type: null
                };
            }
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input'],
    data() {
        return {
            datePickerPt,
            id: uuid.v4(),
            primaryParams: [
                {
                    name: "Tipo de Cliente",
                    key: "type_customer"
                },
                {
                    name: "Tipo de Campanha",
                    key: "type_campaign"
                },
            ],
            secondaryParams: [],
            tertiaryParams: [],
            chartTypes: [
                {
                    name: "Coluna",
                    key: "column"
                },
                {
                    name: "Pizza",
                    key: "sector"
                },
                {
                    name: "Linha",
                    key: "line"
                },
                {
                    name: "Barra",
                    key: "bar"
                },
            ],
            loading: false,
            chartLoading: false,
            modalChooseColor: {
                color: '#317f98',
                fieldIndex: null,
            },
            chart: this.$props.value,
            timeout: null
        };
    },
    watch: {
        'chart': {
            handler(val) { this.$emit('input', val) },
            deep: true
        },
        'chart.primary'(val) {
            this.chart.secondary = null;
            this.chart.fields = [];
            this.chart.data = null;
            this.secondaryParams = [];
            this.tertiaryParams = [];

            if(val != null) {
                this.loading = true;

                api.get(`/reports/custom/params/${val.key}`)
                    .then(res => {
                        const { body } = res.data;

                        this.secondaryParams = body.secondary;
                        this.tertiaryParams = body.tertiary;
                    })
                    .catch(() => {
                        this.secondaryParams = [];
                        this.tertiaryParams = [];
                    })
                    .finally(() => this.loading = false);
            }
        },
        'chart.secondary': {
            handler() { this.loadChartData() },
            deep: true
        },
        'chart.from_date': {
            handler() { this.loadChartData() },
            deep: true
        },
        'chart.to_date': {
            handler() { this.loadChartData() },
            deep: true
        },
        'chart.fields': {
            handler() { this.loadChartData() },
            deep: true
        }
    },
    methods: {
        moment,
        disableDateTime,
        /**
         * Busca os dados dos gráficos já formatados para a geração de gráficos de acordo
         * com os parâmetros especificados na página.
         *
         * O timeout serve para impedir que o gráfico fique atualizando continuamente.
         * As validações impedem o gráfico de atualizar caso não haja os parâmetros necessários.
         */
        loadChartData() {
            clearTimeout(this.timeout)

            const { secondary, fields } = this.chart;
            if(fields.length === 0 || secondary == null || fields.filter(e=> e.tertiary != null).length !== fields.length)
                return;

            this.timeout = setTimeout(() => {
                const params = {
                    from_date: this.chart.from_date,
                    to_date: this.chart.to_date,
                    primary: this.chart.primary,
                    secondary: this.chart.secondary,
                    fields: this.chart.fields,
                }

                this.chartLoading = true;
                api.post(`/reports/custom/`, params)
                    .then(res => {
                        const { body } = res.data;

                        this.chart.data = body;
                    })
                    .catch(() => {
                        this.chart.data = null
                    })
                    .finally(() => this.chartLoading = false);
            }, 400);
        },
        /**
         * Adiciona um campo comparativo ao gráfico.
         */
        addField() {
            this.chart.fields.push({
                color: this.generateLightColorHex(),
                tertiary: null,
            });
        },
        /**
         * Abre o modal de alteração de cor para modificar a cor do campo.
         * @param {number} fieldIndex Index do campo no array respectivo
         * @param {string} color Cor em hexadecimal atual do campo
         */
        openChooseColorModal(fieldIndex, color) {
            this.modalChooseColor = {
                fieldIndex,
                color,
            };
            this.$bvModal.show(`${this.id}-choose-modal`);
        },
        /**
         * Altera a cor do campo em questão.
         * @param {object} options
         */
        changeColor(options) {
            this.chart.fields[options.fieldIndex].color = options.color;
        },
        /**
         * Filtra as opções de campo disponíveis para impedir múltiplos campos
         * com os mesmos parâmetros.
         * @param {object[]} options
         * @returns {object[]}
         */
        filterOptions(options) {
            return Array.from(options).filter(e =>
                this.chart.fields.findIndex(f =>
                    f.tertiary != null && f.tertiary.key == e.key
                ) < 0
            );
        },
        /**
         * Gera cores em hexadecimal claras.
         */
        generateLightColorHex() {
            const selectedColors = Array.from(this.chart.fields).map(e => e.color) ?? [];

            const freeColors = colors.filter(e => !selectedColors.includes(e));

            if(freeColors.length > 0)
                return freeColors[0];

            let color = "#";
            for (let i = 0; i < 3; i++)
                color += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);

            return color;
        },
    }
}
</script>