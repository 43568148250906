<script>
import {Pie} from 'vue-chartjs';

export default {
    extends: Pie,
    props: {
        data: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: false
                },
                ...this.options
            },
            chartData: this.$props.data
        }
    },
    mounted() {
        this.renderChart(this.data, this.chartOptions);
    },
    watch: {
        data: {
            handler(val) {
                if(val != null && val != this.chartData) {
                    this.chartData = this.$props.data
                    this.renderChart(this.chartData, this.chartOptions);
                }
            },
            deep: true
        }
    }
}
</script>
