<template>
    <section class="px-5">
        <loading v-if="loading"/>
        <b-form-group>
            <b-input-group size="lg" class="d-flex align-items-end justify-content-between">
                <b-input-group class="input-group-merge search flex-grow-1 my-1">
                    <b-input-group-prepend is-text>
                        <feather-icon size="25" icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                        v-model="filters.search"
                        placeholder="Buscar"
                    />
                </b-input-group>

                <div class="d-flex flex-column text-left mx-2 my-1">
                    <label class="m-0" for="filter-start">Início</label>
                    <b-form-datepicker
                        id="filter-start"
                        v-model="filters.initial_date"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        placeholder="Data final"
                        :max="max_date"
                    ></b-form-datepicker>
                </div>

                <div class="d-flex flex-column text-left mx-2 my-1">
                    <label class="m-0" for="filter-end">Final</label>
                    <b-form-datepicker
                        id="filter-end"
                        v-model="filters.final_date"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        placeholder="Data final"
                        :max="today"
                        :min="min_date"
                    ></b-form-datepicker>
                </div>

                <div class="d-flex flex-column mx-2 my-1">
                    <select class="form-control" v-model="filters.order_column">
                        <option :value="null">Ordenar por</option>
                        <option value="name">Nome</option>
                        <option value="created_at">Data de Criação</option>
                    </select>
                </div>

                <b-button @click="getAttendants" class="font-weight-bold px-5 fs-6 my-1 mx-2" variant="primary">Filtrar</b-button>
            </b-input-group>
        </b-form-group>
        <common-table-custom
            v-bind="attendantsTable"
            :show-details="false"
        >
            <template v-slot:footer>
                <td class="text-left">Total</td>
                <td>{{ attendantsTable.misc ? attendantsTable.misc.total : 0 }}</td>
                <td
                    :colspan="5"
                    class="text-right"
                >
                    <b-link
                        v-if="attendantsTable.misc && attendantsTable.misc.current_page != attendantsTable.misc.last_page"
                        @click="getAttendants(attendantsTable.misc.current_page + 1)"
                    >
                        + Mostrar mais
                    </b-link>
                </td>
            </template>
        </common-table-custom>
    </section>
</template>

<script>
import { BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BButton, BFormDatepicker } from 'bootstrap-vue';
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import api from '@/api';
import Loading from "@/views/components/Loading.vue";

let attendantsTable = {
    fields: [
        {
            key: "name",
            label: "Atendente",
            class: "text-left"
        },
        {
            key: "contact_count",
            label: "Contatos"
        },
        {
            key: "pendent_count",
            label: "Pendentes"
        },
        {
            key: "open_count",
            label: "Em Aberto"
        },
        {
            key: "supervisor_count",
            label: "Em Supervisor"
        },
        {
            key: "average_answer_time_count",
            label: "Tempo de Resp.",
            formatter: (val) => {
                const days = Math.floor(val / (24 * 60 * 60));
                const hours = Math.floor(val / (60 * 60));
                const minutes = Math.floor(val / 60) % 60;
                const seconds = Math.floor(val) % 60;

                if(days > 0) return `${hours}d`;
                if(hours > 0) return `${hours}h`;
                if(minutes > 0) return `${minutes}m`;
                if(seconds > 0) return `${seconds}s`;
            }
        },
    ],
};
export default {
    components: {
        BFormGroup,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        Loading,
        BButton,
        CommonTableCustom,
        BFormDatepicker
    },
    data() {
        return {
            attendantsTable,
            filters: {
                initial_date: null,
                final_date: null,
                order_column: null,
                search: null
            },
            loading: false
        };
    },
    computed: {
        min_date() {
            if (this.filters.initial_date !== null) {
                return this.filters.initial_date;
            }

            return new Date(0);
        },
        max_date() {
            if (this.filters.final_date !== null) {
                return this.filters.final_date;
            }
            return this.today;
        },
        today() {
            return new Date();
        }
    },
    created() {
        this.getAttendants();
    },
    methods: {
        getAttendants(page = 1) {
            this.loading = true;
            const params = { ...this.filters, page };

            api.get('/reports/attendants', { params })
            .then(response => {
                const { type, body, misc } = response.data;

                if(type == 'success') {
                    this.attendantsTable.items = page > 1
                        ? [...this.attendantsTable.items, ...body]
                        : body;
                    return this.attendantsTable.misc = misc;
                }
                throw response;
            })
            .catch(() => {
                this.attendantsTable.items = [];
                this.attendantsTable.misc = null;
            })
            .finally(() => this.loading = false)
        },
    }
}
</script>
