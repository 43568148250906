<template>
    <section class="px-5">
        <b-form-group>
            <div class="row align-items-end g-2" size="lg">

                <div class="col-12 col-lg-6">
                    <multiselect
                        v-model="filters.form_id"
                        :options="forms"
                        label="name"
                        :loading="loading.forms"
                        trackBy="_id"
                        :showLabels="false"
                        placeholder="Buscar formulário"
                    >
                        <template v-slot:noResult>
                            Nenhum elemento encontrado.
                        </template>
                        <template v-slot:noOptions>
                            Nenhum elemento encontrado.
                        </template>
                    </multiselect>
                </div>

                <div class="col-6 col-lg-3 col-xl-2 text-left mt-2">
                    <label class="m-0" for="start">Início</label>
                    <b-form-datepicker
                        id="start"
                        v-model="filters.initial_date"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        placeholder="Data inicial"
                        :max="max_date"
                    ></b-form-datepicker>
                </div>

                <div class="col-6 col-lg-3 col-xl-2 text-left mt-2">
                    <label class="m-0" for="filter-end">Final</label>
                    <b-form-datepicker
                        id="filter-end"
                        v-model="filters.final_date"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        placeholder="Data final"
                        :max="today"
                        :min="min_date"
                    ></b-form-datepicker>
                </div>

                <div class="col-12 col-xl-2 mt-2">
                    <b-button
                        class="w-100"
                        style="height: 35px;"
                        variant="primary"
                        @click="load"
                    >
                        Filtrar
                    </b-button>
                </div>
            </div>
        </b-form-group>

        <div class="row position-relative">
            <loading v-if="loading.dispatch" />

            <div class="col-12 col-md-6 d-flex justify-content-center align-items-start py-3">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="icon-with-background mr-3 p-5">
                        <img src="@/assets/images/icons/send.svg?data">
                    </div>
                    <div class="d-flex flex-column text-left justify-content-center">
                        <span>Envios</span>
                        <span class="big-number text-blue">{{ shotCount.messages_count || '-' }}</span>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6 send-cards">
                <div class="bg-primary p-3 d-flex rounded send-card align-items-center">
                    <div class="icon-with-background mr-3 bg-white">
                        <img src="@/assets/images/icons/staff.svg?data">
                    </div>
                    <div class="text-left">
                        <p class="text-white m-0">Clientes Impactados</p>
                        <span class="text-white">{{ shotCount.customers_impacted || '-' }}</span>
                    </div>
                </div>
                <div v-if="user.can_shot" class="bg-primary p-3  d-flex rounded send-card align-items-center">
                    <div class="icon-with-background mr-3 bg-white">
                        <img src="@/assets/images/icons/choice.svg?data">
                    </div>
                    <div class="text-left">
                        <p class="text-white m-0">Campanhas</p>
                        <span class="text-white">{{ shotCount.campaign_count || '-' }}</span>
                    </div>
                </div>
                <div class="bg-primary p-3 d-flex rounded send-card align-items-center">
                    <div class="icon-with-background mr-3 bg-white">
                        <img src="@/assets/images/icons/time.svg?data">
                    </div>
                    <div class="text-left">
                        <p class="text-white m-0">Tempo Médio Entre Envios</p>
                        <span class="text-white">{{ shotCount.timeDifference || '--:--' }}</span>
                    </div>
                </div>
                <div class="bg-primary p-3 d-flex rounded send-card align-items-center">
                    <div class="icon-with-background mr-3 bg-white">
                        <img src="@/assets/images/icons/returned-mail.svg?data">
                    </div>
                    <div class="text-left">
                        <p class="text-white m-0">Contatos Retornados</p>
                        <span class="text-white">{{ shotCount.returned_contacts || '-' }}</span>
                    </div>
                </div>
                <div class="bg-primary p-3 d-flex rounded send-card align-items-center">
                    <div class="icon-with-background mr-3 bg-white">
                        <img src="@/assets/images/icons/double-tick.svg?data">
                    </div>
                    <div class="text-left">
                        <p class="text-white m-0">Mensagens Visualizadas</p>
                        <span class="text-white">{{ shotCount.read_messages || '-' }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row position-relative">
            <loading v-if="loading.customers" />
            <div class="col-12 mt-5">
                <h6 class="text-blue text-left font-weight-bold mb-4">Geografia dos Clientes</h6>
                <table class="table">
                    <thead>
                    <tr class="text-blue">
                        <th scope="col">UF</th>
                        <th scope="col">Cadastrados</th>
                        <th scope="col">Ativos</th>
                        <th scope="col">Bloqueados</th>
                    </tr>
                    </thead>
                    <tbody v-if="customersByUF.length > 0">
                        <tr class="text-black-50" v-for="(report, i) in customersByUF" :key="i">
                            <th scope="row">{{ report.uf }}</th>
                            <td>{{ report.customers_count }}</td>
                            <td>{{ report.active_count }}</td>
                            <td>{{ report.blocked_count }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4" class="text-center">Nenhum registro encontrado</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import {BFormGroup, BFormDatepicker, BButton} from 'bootstrap-vue';
import api from "@/api";
import Loading from '@/views/components/Loading.vue';
import Multiselect from 'vue-multiselect'

export default {
    components: {
        BFormGroup,
        BFormDatepicker,
        BButton,
        Loading,
        Multiselect
    },
    data() {
        return {
            filters: {
                initial_date: null,
                final_date: null,
                form_id: null,
            },
            attendanceSort: null,
            forms: [],
            shotCount: {
                messages_count: null,
                customers_impacted: null,
                campaign_count: null,
                returned_contacts: null,
                read_messages: null,
                timeDifference: null
            },
            customersByUF: [],
            loading: {
                forms: false,
                dispatch: false,
                customers: false
            }
        };
    },
    computed: {
        min_date() {
            if (this.initial_date !== null)
                return this.initial_date;

            return new Date(0);
        },
        max_date() {
            if (this.final_date !== null)
                return this.final_date;
            return this.today;
        },
        today() {
            return new Date();
        }
    },
    created() {
        this.getForms();
        this.load();
    },
    methods: {
        getForms() {
            this.loading.forms = true;
            api.get('/forms', { all: 1, }).then(res => {
                const { type, body } = res.data;

                if(type === 'success')
                    this.forms = body;
            }).finally(() => this.loading.forms = false);
        },
        load() {
            this.getDispatchs();
            this.getCustomersByUF();
        },
        getDispatchs() {
            this.loading.dispatch = true;

            const params = { ...this.filters, form_id: this.filters.form_id ? this.filters.form_id._id : null };

            api.get('/reports/dispatchCount', { params })
                .then(res => this.shotCount = res.data.body)
                .catch(() =>
                    this.$bvToast.toast("Não foi possível carregar o relatório de Envios", { variant: 'error' })
                )
                .finally(() => this.loading.dispatch = false)
        },
        getCustomersByUF() {
            this.loading.customers = true;

            const params = { form_id: this.filters.form_id ? this.filters.form_id._id : null };

            api.get('/reports/customersByUF', { params })
            .then(res => {
                const { body, type } = res.data;

                if(type === 'success')
                    return this.customersByUF = body;

                this.customersByUF = []
            })
            .catch(() => {
                this.customersByUF = [];
                this.$bvToast.toast("Não foi possível carregar o relatório de Geografia dos Clientes", { variant: 'error' });
            })
            .finally(() => this.loading.customers = false);
        },
    }
}
</script>
