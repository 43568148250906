<template>
    <b-modal
        :id="id"
        centered
        title="Cor do campo"
        header-border-variant="light"
        footer-border-variant="light"
        content-class="p-4 position-relative"
        body-class="position-unset"
        lazy
    >

        <div class="d-flex justify-content-center">
            <color-picker v-model="chart.color"></color-picker>
        </div>

        <template #modal-footer="{ ok }">
            <button @click="chooseColor(ok)" class="btn btn-primary px-5">
                Escolher
            </button>
        </template>
    </b-modal>
</template>

<script>
import ColorPicker from 'vue-color-picker-wheel';
import { BModal } from "bootstrap-vue";

export default {
    components: {
        BModal,
        ColorPicker,
    },
    props: {
        options: {
            type: Object,
            default: () => ({
                color: '#000000',
                chartIndex: 0,
                fieldIndex: 0,
            }),
        },
        id: {
            type: String,
            default: () => 'chooseColorModal'
        }
    },
    data() {
        return {
            chart: {
                color: '#000000',
                chartIndex: 0,
                fieldIndex: 0,
            },
        };
    },
    emits: ['choose'],
    watch: {
        options: {
            handler() {
                this.chart = this.options;
            },
            deep: true,
        },
    },
    methods: {
        chooseColor(closeHandle) {
            this.$emit('choose', this.chart);
            closeHandle();
        },
    },
}
</script>

<style>
.position-unset {
    position: unset !important;
}
</style>
