<template>
    <section id="dashboard-reports">
        <b-tabs align="left" nav-class="font-weight-bold">
            <b-tab lazy title="Clientes" :title-link-class="['col-12']">
                <b-card no-body>
                    <b-card-body class="text-center">
                        <clients />
                    </b-card-body>
                </b-card>
            </b-tab>
            <b-tab lazy title="Envios" v-if="$role(['campaigns.get'])">
                <b-card no-body>
                    <b-card-body class="text-center">
                        <dispatch />
                    </b-card-body>
                </b-card>
            </b-tab>
            <b-tab lazy title="Atendimentos">
                <b-card no-body>
                    <b-card-body class="text-center">
                        <attendance />
                    </b-card-body>
                </b-card>
            </b-tab>
            <b-tab lazy title="Relatório Personalizado" v-if="$role(['reports.create'])">
                <b-card no-body>
                    <b-card-body class="text-center">
                        <custom-reports />
                    </b-card-body>
                </b-card>
            </b-tab>
        </b-tabs>
    </section>
</template>

<script>
import { BCard, BCardBody, BTab, BTabs } from "bootstrap-vue";

import Clients from "@/views/pages/dashboard/reports/Clients.vue";
import Attendance from "@/views/pages/dashboard/reports/Attendance.vue";
import Dispatch from "@/views/pages/dashboard/reports/Dispatch.vue";
import CustomReports from "@/views/pages/dashboard/reports/CustomReports.vue";

export default {
    components: {
        BCard,
        BCardBody,
        BTab,
        BTabs,
        Clients,
        Attendance,
        Dispatch,
        CustomReports,
    },
    data() {
        return {
            data: {},
        };
    },
    created() {},
    methods: {},
};
</script>
