<template>
    <table class="w-100">
        <tr v-for="(label, index) in data.labels" :key="`table--chart--row--${index}`">
            <td class="text-left chart--label--column px-2">{{ label }}</td>
            <td class="d-flex align-items-center py-2 px-0 chart--column" >
                <div
                    class="mr-2"
                    :style="`
                        width: ${calcPercent(data.values[index], data.values)}%;
                        background-color: ${data.colors[index] || 'gray'};
                        height: ${data.barHeight || '20px'};
                    `"
                ></div>
                <span :style="`color: ${data.colors[index] || 'gray'}`">{{ data.values[index] }}</span>
            </td>
        </tr>
        <tr>
            <td></td>
            <td class="chart--footer w-100 d-flex align-items-center justify-content-between">
                <span v-for="(period, index) in data.periods" :key="`chart--period--${index}`">{{ period }}</span>
            </td>
        </tr>
    </table>
</template>

<script>
    export default {
        name: 'HorizontalCharBar',
        props: {
            data: {
                type: Object,
                default: function () {
                    return {
                        labels: ['Exemplo 1', 'Exemplo 2'],
                        values: [150, 240, 1500],
                        colors: ['gray', 'red'],
                        periods: ['01 JAN 21', '01 JAN 22']
                    }
                }
            }
        },
        methods: {
            calcPercent(value, data) {
                try {
                    const sum = data.reduce((a, b) => a + b, 0);
                    return (value * 100) / (sum == 0 ? 1 : sum);
                } catch(e) {
                    return 100;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.chart--column {
    border-left: 1px solid rgba(0,0,0,.2);
}

.chart--label--column {
    width: 1%;
    white-space: nowrap
}

.chart--footer {
    border-top: 1px solid rgba(0,0,0,.2);
}
</style>
