<template>
    <b-tabs align="left" nav-class="font-weight-bold">
        <common-table
            :show-details="false"
            v-bind="configTableClients">
        </common-table>
    </b-tabs>
</template>

<script>
import {
    BTabs,
} from "bootstrap-vue";
import CommonTable from "@/views/components/CommonTable"
import api from '@/api';

export default {
    components: {
        CommonTable,
        BTabs
    },
    data() {
        return {
            configTableClients: {
                fields: [
                    {
                        key: "numbers",
                        label: "Números",
                        class: "text-left",
                    },
                    {
                        key: "registereds",
                        label: "Cadastrados",
                    },
                    {
                        key: "actives",
                        label: "Ativos",
                    },
                    {
                        key: "blockages",
                        label: "Bloqueados",
                    },
                ],
                items: [],
            },
        };
    },
    created() {
        this.getClientesNumbers();
    },
    methods: {
        getClientesNumbers() {
            api
                .get('/reports/customersByNumbers')
                .then(response => {
                    const {type, body} = response.data;
                    if (type !== 'success') return;
                    this.configTableClients.items = body;
                })
        }
    }
};
</script>

<style>
</style>
