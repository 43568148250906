<template>
    <section class="px-5 print-container">
        <h3 v-if="!readOnly" class="text-left text-secondary font-weight-bolder mr-2 mb-4 mt-5">Crie gráficos com parâmetros personalizados</h3>
        <img v-else src="/assets/imgs/logo.png" class="img-fluid my-4" alt="Convo4u Logo" width="200" />

        <div v-if="readOnly">
            <span>Relatório gerado em: {{ moment().format('DD/MM/YYYY HH:II') }}</span> <br>
            <span>Por: {{ $store.getters['user/get'].name }}</span>
            <hr>
        </div>

        <div class="row">
            <section
                v-for="(chart, i) in charts" 
                :key="chart.id"
                class="chart"
                :class="{'col-12': !readOnly, 'col-6': readOnly}"
            >
                <chart
                    v-model="charts[i]"
                    @remove="charts.splice(i, 1)"
                    :read-only="readOnly"
                />
                <hr class="mt-5 mb-2">
            </section>
        </div>

        <div v-if="!readOnly" class="d-flex justify-content-start my-5">
            <h5
                role="button"
                class="text-left text-secondary font-weight-bolder"
                @click="addChart"
            >
                <font-awesome-icon icon="fa-solid fa-plus" class="mr-4" /> Adicionar novo gráfico
            </h5>
        </div>

        <div class="d-flex justify-content-end">
            <button @click="exportReport" class="btn btn-secondary ml-2">
                Gerar relatório
            </button>
        </div>
    </section>
</template>

<script>
import { uuid } from 'vue-uuid';
import Chart from './components/Chart.vue';
import moment from 'moment';

export default {
    components: {
        Chart,
    },
    data() {
        return {
            charts: [],
            readOnly: false,
            timeout: null
        };
    },
    methods: {
        addChart() {
            this.charts.push({
                id: uuid.v4(),
                primary: null,
                secondary: null,
                from_date: null,
                to_date: null,
                fields: [],
                data: null,
                type: null
            })
        },
        exportReport() {
            const report = {
                created_at: moment().format('DD/MM/YYYY HH:II'),
                created_by: this.$store.getters['user/get'].name,
                charts: this.charts.map(e => Object.create(e))
            }
            
            this.$store.commit('report/SET_REPORT', report)
            this.$router.push({ name: 'reports/export' })
        },
    }
}
</script>